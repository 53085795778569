import { Injectable } from '@angular/core';
import { OAuth } from '@qaroni-app/auth/types/o-auth';
import { OAuthStorageBaseService } from '@qaroni-core/types/o-auth/o-auth-storage-base-services';

@Injectable({
  providedIn: 'root',
})
export class OAuthWorkstationStorageService extends OAuthStorageBaseService<OAuth> {
  protected lsKey = 'oAuthWorkstations';
}
